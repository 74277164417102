 

import { useState , useEffect ,useContext} from "react";

import { auth } from "../firebase";
import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence } from "firebase/auth";
// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "../components/SoftBox";
import SoftTypography from "../components/SoftTypography";
import SoftInput from "../components/SoftInput";
import SoftButton from "../components/SoftButton";

// Authentication layout components
import IllustrationLayout from "../pages/IllustrationLayout";
import { useStateContext } from '../contexts/ContextProvider';
import {AuthContext} from "../contexts/AuthContext"
// Image
import chat from "../assets/images/illustrations/rocket-white.png";

 

function Illustration() {
  const [rememberMe, setRememberMe] = useState(false);
  const { setActiveMenu } = useStateContext();
   
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  
  const navitage = useNavigate()

  const {dispatch}  = useContext(AuthContext)

  const handleLogin = (e) => {
    e.preventDefault();
    setPersistence(auth, browserSessionPersistence)
      
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        dispatch({type:"LOGIN", payload:user})
        navitage("/jec")

       
         
      })
      .catch((error) => {
        setError(true);
      });
  };

  useEffect(() => {
     
      setActiveMenu(false);
     
  }, []);
    
  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={{
        image: chat,
        title: '"Innovura Dashboard"',
        description:
          "A Single Communication Zone for IoT and QoT",
      }}
    >
      <SoftBox component="form" role="form">
        <SoftBox mb={2}>
          <SoftInput type="email" placeholder="Email" size="large" onChange={(e) => setEmail(e.target.value)}/>
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput type="password" placeholder="Password" size="large" onChange={(e) => setPassword(e.target.value)}/>
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" size="large" fullWidth onClick={handleLogin}>
            sign in
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
        {error && <span>Wrong email or password!</span>}
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}

export default Illustration;
