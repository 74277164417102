import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from './contexts/ContextProvider';
import { useContext } from "react";
import { Navbar, Footer, Sidebar, ThemeSettings } from './components';

import { NewDash,Ecommerce, Orders, Login, Employees, Stacked, Pyramid, Customers,  } from './pages';

import { ThemeProvider } from "@mui/material/styles";
// Soft UI Dashboard PRO React themes
import theme from "../src/assets/theme";
import themeRTL from "../src/assets/theme/theme-rtl"
import { AuthContext } from "./contexts/AuthContext";
import { Navigate } from "react-router-dom";

 

function App() {


 

  const {currentUser} = useContext(AuthContext)
  const {activeMenu} = useStateContext();
  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };
  useEffect(() => {
    document.title = 'Innovura Dashboard Demo';
  });


  return (
    <div  >
      <BrowserRouter>
      <ThemeProvider theme={themeRTL}>
        <div className="flex relative dark:bg-main-dark-bg">
            <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}> 
            <TooltipComponent
                content="Settings"
                position="Top"
              >
                  <button 
                      className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                      style={{ background:'blue', borderRadius: '50%' }}
                  >
                    <FiSettings/>
                  </button>
            </TooltipComponent>
            </div>
            {activeMenu ? (
              <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                <Sidebar/>
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar/>
              </div>
            )}

          <div
              className={
                activeMenu
                  ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
                  : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
              }>
                <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">

                <Navbar />
                </div>
                <Routes>
                  {/* dashboard  */}
                  <Route path="/" element={<RequireAuth><NewDash /></RequireAuth>} /> 
                  <Route path="/ecommerce" element={<RequireAuth><Ecommerce /></RequireAuth>} />
                  <Route path="/JEC" element={<NewDash />}/>

                  {/* pages  */}
                   <Route path="/device" element={<RequireAuth><Orders /></RequireAuth> }/>
                  <Route path="/login" element={<Login/>} />
                  <Route path="/customers" element={<RequireAuth><Customers /></RequireAuth>} />

                 

                </Routes>
          </div>



        </div>
        </ThemeProvider>
      </BrowserRouter>
       
    </div>
  );
}

export default App;
