 
import CountUp from "react-countup";
import {useState ,useEffect,useCallback} from 'react';
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import config from '../../../../config.json';
import axios from 'axios';
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";

function DefaultCounterCard({ color,title, description, prefix, suffix,iot_host,iot_id , field}) {

  const [getdata, setData] = useState(null);
  const [replydata, setReplyData] = useState([]);
  const [displaydata, setDisplayData] = useState([]);
  const [poststring, setPostString] = useState({
    "gateway": 
    {
               "iot_host": iot_host,
               "iot_id": iot_id,
               "type":"iot_id"
    },

    "field":
    {
        "col":[ ]
    },
    "type":
    {
       "select":"",
       "calculate":"MAX"
       
    },
    "time":
    {
       "gte": " ", 
       "lte": " "
    }
    
     

});


const headers = {
  'Content-Type': 'text/plain',
  'Access-Control-Allow-Credentials':'ture',
  'Access-Control-Allow-Origin':'*'

}


const [state, setState] = useState("state");

const loadDataOnlyOnce = useCallback(() => {
        console.log(`I need ${state}!!`);
      }, [state]);

const postData = async () =>{
       
    
        await axios.post(config.get_most_update, poststring,{headers: headers})
        .then((response) => {
            /*console.log(response.data)*/
            try
            {
              setReplyData(response.data)
         
              
            }
            catch (error) {console.log(error)}
        })
        .catch((error) => console.log(error));
        
        
        
       
      
         
      }
const clone = async ()=>{
            
           
           
            if (typeof(field) !=='undefined')
            
            {
          
              if (Object.keys(replydata).length > 0) 
              {
                  for (var i = 0; i < field.length; i++)
                  {
                    Object.entries(replydata).map(([key, value]) => 
                    {
                  
                      if (key.match(field[i])) {
                      
                        if ((Object.keys(field).length) > (Object.keys(displaydata).length))
                        {
                          setDisplayData((displaydata)=>[...displaydata , {[key]:value}])
                        }
                        
      
                        
                      }
                    
                    })
                  }
              }
              if (Object.keys(displaydata).length > 0)
              {
               // console.log(iot_id + " " + displaydata[0][field[0]])
              }
              
              
            }
      
      }
const getData = async () =>{
             
          
        await axios.get(config.testurl)
            .then((response) => {setData(response.data); });
      
      
        console.log(getdata);
       
      
      }
       
       
      
      
useEffect(() => {
         
          postData();
          
        }, [loadDataOnlyOnce]);
      
useEffect(()=>{
          /*console.log('this items changed')*/
          clone();
        }, [replydata])
        
useEffect(() => {
          const interval = setInterval(() => {
            console.log('Logs every minute');
          }, 120000);
        
          return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        }, [])

        
  return (
    <Card>
      <SoftBox p={3} textAlign="center" lineHeight={1}>
        <SoftTypography variant="h4" color={color} fontWeight="bold" textGradient>
          {prefix && (
            <SoftTypography component="span" variant="h4" fontWeight="bold">
              {prefix}
            </SoftTypography>
          )}
          <SoftBox display="inline-block" mx={0.2}>
              {displaydata.length>0? displaydata[0][field[0]]:0 }
          </SoftBox>
          {suffix && (
            <SoftTypography component="span" variant="h6" fontWeight="bold">
              {suffix}
            </SoftTypography>
          )}
        </SoftTypography>
        <p></p>
        <SoftTypography variant="h6" fontWeight="bold" textTransform="capitalize">
          {title}
        </SoftTypography>
        {description && (
          <SoftTypography
            variant="button"
            fontWeight="regular"
            opacity={0.8}
            textTransform="capitalize"
          >
            {description}
          </SoftTypography>
        )}
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of DefaultCounterCard
DefaultCounterCard.defaultProps = {
  color: "info",
  prefix: "",
  suffix: "",
  description: "",
};

// Typechecking props for the BlogCard
DefaultCounterCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iot_host:PropTypes.string.isRequired,
  iot_id:PropTypes.string.isRequired,
  field: PropTypes.array,

};

export default DefaultCounterCard;
