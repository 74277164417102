import { useState , useContext } from "react";
 
import React from "react";
import ReactDOM from "react-dom";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import GridLayout from "react-grid-layout";
 
 


import NewWeatherCard from "../examples/Cards/NewWeatherCard"; 
import MqttWeatherCard from "../examples/Cards/MqttWeatherCard";
import MyDefaultCounterCard from "../examples/Cards/CounterCards/MyDefaultCounterCard";
import CountCard from "../examples/Cards/CounterCards/CountCard";
import DefaultCounterCard from "../examples/Cards/CounterCards/DefaultCounterCard";
import InfoCounterCard from "../examples/Cards/CounterCards/InfoCounterCard";
import DefaultProjectCard from "../examples/Cards/ProjectCards/DefaultProjectCard";
import NotificationItem from "../examples/Items/NotificationItem";
// Data
import miniGradientLineChartData from "../layouts/dashboards/crm/data/miniGradientLineChartData";
 


import rankingsListData from "../layouts/dashboards/crm/data/rankingsListData";
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
 

import reportsBarChartData from "../layouts/dashboards/default/data/reportsBarChartData";
//import NotificationItem from "../examples/Items/NotificationItem";
import Icon from "@mui/material/Icon";
import { AuthContext } from '../contexts/AuthContext';
import { useStateContext } from '../contexts/ContextProvider';

import MyImage from '../assets/images/snap.jpg';
import MyImage1 from '../assets/images/snap1.jpg';

const NewDash = () => {

  const { visitorsChart, incomeChart } = miniGradientLineChartData;
  const {activeMenu} = useStateContext();
  const {currentUser} = useContext(AuthContext);

  /*console.log("DashBoard : "+currentUser)*/

  var layout = [
    { i: "a", x: 0, y: 1, w: 4, h: 8 },
    { i: "b", x: 2, y: 0, w: 4, h: 4 },
    { i: "c", x: 4, y: 0, w: 4, h: 4 },
    { i: "d", x: 10, y: 10, w: 4, h: 4 }
  ];
  const [temperature, setTemperature] = useState(21);
 

  const { chart, items } = reportsBarChartData;
  const { transactionsData, revenueData } = rankingsListData;
 
   
  return (
     <div>
         <ResponsiveGridLayout
           
        
           cols={{ lg: 8, md: 6}}
         
           rowHeight={80}
           width={1200}
           breakpoints={{ lg: 1200, md: 996, sm: 768 }}
         >
         
          
           <div key="1" data-grid={{ w: 2, h: 1, x: 0, y: 0}}>
       
                     <NewWeatherCard
                     title="Temperature Office Area"
                    
                     iot_host="gateway179"
                     iot_id  = "17004"
                     field = {['temp','humidity' ]}
                     refresh = {120000}
                      
                   />
           </div>
 
           <div key="2" data-grid={{ w: 2, h: 1, x: 2, y: 0}}>
                 <NewWeatherCard
                     title="Temperature Male Toilet"
                    
                     iot_host="gateway179"
                     iot_id  = "17003"
                     field = {['temp','humidity' ]}
                     refresh = {120000}
                      
                   />
           </div>
 
           <div key="3" data-grid={{ w: 2, h: 1, x: 4, y: 0}}>
                 <NewWeatherCard
                     title="Temperature Female Toilet"
                    
                     iot_host="gateway179"
                     iot_id  = "17201"
                     field = {['temp','humidity' ]}
                     refresh = {120000}
                      
                   />
           </div>
 
            
           
           
       </ResponsiveGridLayout>
 
       <ResponsiveGridLayout
           className="layout"
           layout={layout}
           cols={{ lg: 8, md: 6, sm: 2 }}
         
           rowHeight={75}
           width={1200}
           breakpoints={{ lg: 1200, md: 996, sm: 768 }}
         >
  
      
 
           <div key="5" data-grid={{ w: 2, h: 2, x: 0, y: 0}}>
                <CountCard
                     
                 
                     title="Female Toliet"
                     devicetype ="human"
                     iot_host="gateway179"
                     count_field ="here"
                     count_device= {['37005','37006']}
                     refresh = {3000}

                   />
           </div>
 
           <div key="6" data-grid={{ w: 2, h: 2, x: 2, y: 0 }}>
           <CountCard
                     
                 
                     title="Male Toliet"
                     devicetype ="human"
                     iot_host="gateway179"
                     count_field ="here"
                     count_device= {['37001','37002','37003' ]}
                     refresh = {3000}

                   />
           </div>
           <div key="7" data-grid={{ w: 2, h: 2, x: 4, y: 0 }}>
           <CountCard
                     
                 
                     title="Urinal "
                     devicetype ="human"
                     iot_host="gateway179"
                     count_field ="here"
                     count_device= {['37011','37012','37013' ]}
                     refresh = {3000}

                   />
           </div>
           <div key="9" data-grid={{ w: 1, h: 2, x: 0, y: 3 }}>
           <DefaultCounterCard
                     
                    
                     //prefix="0"
                     suffix="mg/m3"
                     title="PM2.5"
                     description="Office Area"
                     iot_host="gateway179"
                     iot_id  = "57001"
                     field = {['pmtwo' ]}
                   />
           </div>
           <div key="10" data-grid={{ w: 1, h: 2, x: 1, y: 3 }}>
           <DefaultCounterCard
                     
                     
                     //prefix="0"
                     suffix="mg/m3"
                     title="PM10"
                     description="Office Area"
                     iot_host="gateway179"
                     iot_id  = "57001"
                     field = {['pmten' ]}
                   />
           </div>
           <div key="11" data-grid={{ w: 1, h: 2, x: 4, y: 3 }}>
           <DefaultCounterCard
                     
                     
                     //prefix="0"
                     suffix="mg/m3"
                     title="Male Toilet Ammonia"
                     description="No Alert"
                     iot_host="gateway179"
                     iot_id  = "67002"
                     field = {['no3','alert']}
                   />
           </div>
 
           <div key="12" data-grid={{ w: 1, h: 2, x: 5, y: 3 }}>
           <DefaultCounterCard
                     
                     count={0}
                     //prefix="0"
                     suffix="mg/m3"
                     title="Female Toilet Ammonia"
                     description="No Alert"
                     iot_host="gateway179"
                     iot_id  = "67003"
                     field = {['no3','alert' ]}
                   />
           </div>
 
 
        

           <div key="13" data-grid={{ w: 1, h: 2, x: 2, y:3 }}>
           <InfoCounterCard
                     
                     //count={417}
                     prefix={['Full','Empty' ]}
                     //suffix=" /3"
                     title="Male W/C Soap Dispenser "
                     iot_host="gateway179"
                     iot_id  = "90003"
                     field = {['empty' ]}
                     value ={0}
                     refresh = {60000}
                   />
           </div>
           <div key="19" data-grid={{ w: 1, h: 2, x: 3, y:3 }}>
           <InfoCounterCard
                     
                     //count={417}
                     prefix={['Full','Empty' ]}
                     //suffix=" /3"
                     title="Femlae W/C Soap Dispense"
                     iot_host="gateway179"
                     iot_id  = "90002"
                     field = {['empty' ]}
                     value ={0}
                     refresh = {60000}
                   />
           </div>

           <div key="20" data-grid={{ w: 1, h: 2, x: 0, y:5 }}>
           <InfoCounterCard
                     
                     //count={417}
                     prefix={['Full','Empty' ]}
                     //suffix=" /3"
                     title="Pantry Soap Dispenser"
                     iot_host="gateway179"
                     iot_id  = "90004"
                     field = {['empty' ]}
                     value ={0}
                     refresh = {60000}
                   />
           </div>

           <div key="21" data-grid={{ w: 1, h: 2, x: 1, y:5 }}>
           <InfoCounterCard
                     
                     //count={417}
                     prefix={['Leak','Normal' ]}
                     //suffix=" /3"
                     title="Male Toilet P1"
                     iot_host="gateway179"
                     iot_id  = "27001"
                     field = {['leak' ]}
                     value ={0}
                     refresh = {30000}
                   />
           </div>

           <div key="22" data-grid={{ w: 1, h: 2, x: 2, y:5 }}>
           <InfoCounterCard
                     
                     //count={417}
                     prefix={['Leak','Normal' ]}
                     //suffix=" /3"
                     title="Male Toilet P2"
                     iot_host="gateway179"
                     iot_id  = "27002"
                     field = {['leak' ]}
                     value ={0}
                     refresh = {30000}
                   />
           </div>

           <div key="23" data-grid={{ w: 1, h: 2, x: 3, y:5 }}>
           <InfoCounterCard
                     
                     //count={417}
                     prefix={['Leak','Normal' ]}
                     //suffix=" /3"
                     title="Male Toilet P3"
                     iot_host="gateway179"
                     iot_id  = "27002"
                     field = {['leak' ]}
                     value ={0}
                     refresh = {30000}
                   />
           </div>

           <div key="24" data-grid={{ w: 1, h: 2, x: 4, y:5 }}>
           <InfoCounterCard
                     
                     //count={417}
                     prefix={['Leak','Normal' ]}
                     //suffix=" /3"
                     title="Female Toilet P1"
                     iot_host="gateway179"
                     iot_id  = "27006"
                     field = {['leak' ]}
                     value ={0}
                     refresh = {30000}
                   />
           </div>

           <div key="25" data-grid={{ w: 1, h: 2, x: 5, y:5 }}>
           <InfoCounterCard
                     
                     //count={417}
                     prefix={['Leak','Normal' ]}
                     //suffix=" /3"
                     title="Female Toilet P2"
                     iot_host="gateway179"
                     iot_id  = "27007"
                     field = {['leak' ]}
                     value ={0}
                     refresh = {30000}
                   />
           </div>

           


           

 

 
           
           
 
    
           
       </ResponsiveGridLayout>
        

     </div>
  );
};
export default NewDash ;
