import axios from 'axios';
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
 
import Card from "@mui/material/Card";
 
 
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";


function MyDefaultCounterCard({ color, title, description, prefix, suffix }) {
 

  return (
    <Card>
      <SoftBox p={3} textAlign="center" lineHeight={1.25}>
        <SoftTypography variant="h1" color={color} fontWeight="bold" textGradient>
          {prefix && (
            <SoftTypography component="span" variant="h1" fontWeight="bold">
              {prefix}
            </SoftTypography>
          )}

            
          
          {suffix && (
            <SoftTypography component="span" variant="h1" fontWeight="bold">
              {suffix}
            </SoftTypography>
          )}

        </SoftTypography>
        <SoftTypography variant="h6" fontWeight="bold" textTransform="capitalize">
          {title}
        </SoftTypography>
        {description && (
          <SoftTypography
            variant="button"
            fontWeight="regular"
            opacity={0.8}
            textTransform="capitalize"
          >
            {description}
          </SoftTypography>
        )}
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of DefaultCounterCard
MyDefaultCounterCard.defaultProps = {
  color: "info",
  prefix: "",
  suffix: "",
  description: "",
};

// Typechecking props for the BlogCard
MyDefaultCounterCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
   
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default MyDefaultCounterCard;
