import React, { useEffect , useContext, useState} from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { AiOutlineLogout } from 'react-icons/ai';
import { BsChatLeft } from 'react-icons/bs';
import { RiNotification3Line } from 'react-icons/ri';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { signOut} from "firebase/auth";
import { auth } from "../firebase";
import avatar from '../data/avatar.jpg';
import { Cart, Chat, Notification, UserProfile } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from "react-router-dom";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (

  
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);


const Navbar = () => {
  const { currentColor, activeMenu, setActiveMenu, handleClick, isClicked, setScreenSize, screenSize } = useStateContext();
  const {currentUser} = useContext(AuthContext);
  const navitage = useNavigate()
  const [haveUser , setHaveUser] = useState(false);
  const {dispatch}  = useContext(AuthContext)

  
    /* console.log("NavBar ："+currentUser.email)*/
 

  const handleOut = () => {
    
    signOut(auth).then(() => {
      // Sign-out successful.
      setHaveUser(false)
      console.log("Sign Out Good")
      dispatch({type:"LOGOUT"})
      navitage("/login")


    }).catch((error) => {
      // An error happened.
      console.log(error)
    });
    
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if(currentUser !==null)
    {
      if (screenSize <= 900) {
        setActiveMenu(false);
      } else {
        setActiveMenu(true);
      }
      setHaveUser(true)
    }
    
  }, [screenSize,currentUser]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
  
    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">
      {haveUser?
      <NavButton title="Menu" customFunc={handleActiveMenu} color={currentColor} icon={<AiOutlineMenu />} />:<div></div>
      }
      
      <div className="flex">
      {haveUser?
   
        <TooltipComponent content="Profile" position="BottomCenter">
          <div
            className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
           
          >
           
            <p>
              <span className="text-gray-400 text-14">Hi,</span>{' '}
              <span className="text-gray-400 font-bold ml-1 text-14">
                   {haveUser?currentUser.email:<div></div>}
              </span>
            </p>
            <MdKeyboardArrowDown className="text-gray-400 text-14" />
          </div>
        </TooltipComponent>:<div></div>}

        {haveUser?
        <NavButton title="Logout" customFunc={handleOut}  color={currentColor} icon={<AiOutlineLogout />} />:<div></div>
        }
            
      </div>
    </div>
  );
};

export default Navbar;