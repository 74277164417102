 
import PropTypes from "prop-types";
import {useState ,useEffect,useCallback} from 'react';
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import config from '../../../config.json';
import axios from 'axios';

import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import { GiConsoleController } from "react-icons/gi";




function NewWeatherCard({ color, title, iot_host,iot_id , field , refresh   }) {
 
  const [getdata, setData] = useState(null);
  const [replydata, setReplyData] = useState([]);
  const [displaydata, setDisplayData] = useState([]);
  const [poststring, setPostString] = useState({
    "gateway": 
    {
               "iot_host": iot_host,
               "iot_id": iot_id,
               "type":"iot_id"
    },

    "field":
    {
        "col":[ ]
    },
    "type":
    {
       "select":"",
       "calculate":"MAX"
       
    },
    "time":
    {
       "gte": " ", 
       "lte": " "
    }
    
     

});

const headers = {
  'Content-Type': 'text/plain',
  'Access-Control-Allow-Credentials':'ture',
  'Access-Control-Allow-Origin':'*'

}

const [state, setState] = useState("state");


const loadDataOnlyOnce = useCallback(() => {
  console.log(`I need ${state}!!`);
}, [state]);
        
const postData = async () =>{
       
    
  await axios.post(config.get_most_update, poststring ,{headers: headers})
  .then((response) => {
      /*console.log(response.data)*/
      try
      {
        setReplyData(response.data)
   
        
      }
      catch (error) {console.log(error)}
  })
  .catch((error) => console.log(error));
  
  
  
 

   
}
const clone = async ()=>{
      
     
     
      if (typeof(field) !=='undefined')
      
      {
    
        if (Object.keys(replydata).length > 0) 
        {
            for (var i = 0; i < field.length; i++)
            {
              Object.entries(replydata).map(([key, value]) => 
              {
            
                if (key.match(field[i])) {
                
                  if ((Object.keys(field).length) > (Object.keys(displaydata).length))
                  {
                    setDisplayData((displaydata)=>[...displaydata , {[key]:value}])
                  }
                  

                  
                }
              
              })
            }
        }
        if (Object.keys(displaydata).length > 0)
        {
         // console.log(iot_id + " " + displaydata[0][field[0]])
        }
        
        
      }

}
const getData = async () =>{
       
    
  await axios.get(config.testurl)
      .then((response) => {setData(response.data); });


  console.log(getdata);
 

}
 
 
useEffect(() => {
         
  postData();
  
}, [loadDataOnlyOnce]);

 

  useEffect(()=>{
    /*console.log('this items changed')*/
    clone();
     
  }, [replydata])
  
  useEffect(() => {
    const interval = setInterval(() => {
      postData();
      //console.log('Logs every '+refresh+ ' ms '+iot_id);
    }, refresh);
  
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  return (
   
    <Card>
      <SoftBox
        bgColor={color}
        p={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        variant="gradient"
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8} sx={{ lineHeight: 0 }}>
            <SoftTypography
              variant="button"
              color={color === "light" ? "dark" : "white"}
              textTransform="capitalize"
              fontWeight="medium"
              opacity={0.7}
            >
              {title}  
            </SoftTypography>
            <SoftTypography
              variant="h5"
              color={color === "light" ? "dark" : "white"}
              fontWeight="bold"
            > 
                {displaydata.length>0? displaydata[0][field[0]]:0 }&deg;C     {displaydata.length>0? displaydata[1][field[1]]:0 }  %
            </SoftTypography>
          </Grid>
      
        </Grid>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of WeatherCard
NewWeatherCard.defaultProps = {
  color: "info",
};

// Typechecking props for the WeatherCard
NewWeatherCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  iot_host:PropTypes.string.isRequired,
  iot_id:PropTypes.string.isRequired,
  field: PropTypes.array,
  refresh :PropTypes.number,
  
};

export default NewWeatherCard;
