import axios from 'axios';
import PropTypes from "prop-types";
 
import {useState ,useEffect,useCallback} from 'react';
import Card from "@mui/material/Card";
 
import config from '../../../../config.json';

import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";


function InfoCounterCard({ color, title, description, prefix, suffix, iot_host,iot_id , field ,value , refresh}) {
  const [state, setState] = useState("state");
  const [getdata, setData] = useState(null);
  const [replydata, setReplyData] = useState([]);
  const [displaydata, setDisplayData] = useState([]);
  const [poststring, setPostString] = useState({
    "gateway": 
    {
               "iot_host": iot_host,
               "iot_id": iot_id,
               "type":"iot_id"
    },

    "field":
    {
        "col":[ ]
    },
    "type":
    {
       "select":"",
       "calculate":"MAX"
       
    },
    "time":
    {
       "gte": " ", 
       "lte": " "
    }
    
     

});
 
const headers = {
  'Content-Type': 'text/plain',
  'Access-Control-Allow-Credentials':'ture',
  'Access-Control-Allow-Origin':'*'

}



const loadDataOnlyOnce = useCallback(() => {
        console.log(`I need ${state}!!`);
      }, [state]);

 

        const postData = async () =>{
       
    
          await axios.post(config.get_most_update, poststring,{headers: headers})
          .then((response) => {
              /*console.log(response.data)*/
              try
              {
                setReplyData(response.data)
           
                
              }
              catch (error) {console.log(error)}
          })
          .catch((error) => console.log(error));
          
          
          
         
        
           
        }
        const clone = async ()=>{
              
             
             
              if (typeof(field) !=='undefined')
              
              {
            
                if (Object.keys(replydata).length > 0) 
                {
                    for (var i = 0; i < field.length; i++)
                    {
                      Object.entries(replydata).map(([key, value]) => 
                      {
                    
                        if (key.match(field[i])) {
                        
                          if ((Object.keys(field).length) > (Object.keys(displaydata).length))
                          {
                            setDisplayData((displaydata)=>[...displaydata , {[key]:value}])
                          }
                          
        
                          
                        }
                      
                      })
                    }
                }
                if (Object.keys(displaydata).length > 0)
                {
                  //console.log(iot_id + " " + displaydata[0][field[0]])
                }
                
                
              }
        
        }
        const getData = async () =>{
               
            
          await axios.get(config.testurl)
              .then((response) => {setData(response.data); });
        
        
          //console.log(getdata);
         
        
        }
         
        useEffect(() => {
         
          postData();
          
        }, [loadDataOnlyOnce]);
        
        

        
          useEffect(()=>{
            /*console.log('this items changed')*/
            clone();
          }, [replydata])
          
          useEffect(() => {
            const interval = setInterval(() => {
              postData();
              //console.log('Logs every '+refresh+ ' ms '+iot_id);
            }, refresh);
          
            return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
          }, [])


  return (
    <Card>
      <SoftBox p={3} textAlign="center" lineHeight={1.25}>
        <SoftTypography variant="h4" color={color} fontWeight="bold" textGradient>
 
          { displaydata.length>0? displaydata[0][field[0]]>value?prefix[0]:prefix[1]:prefix[1]  && (
            <SoftTypography component="span" variant="h4" fontWeight="bold">
              {displaydata.length>0? displaydata[0][field[0]]>value?prefix[0]:prefix[1]:prefix[1] }
            </SoftTypography>
          )}

            
          
          { displaydata.length>0? displaydata[0][field[0]]>value?suffix[0]:suffix[1]:suffix[1]  && (
            <SoftTypography component="span" variant="h1" fontWeight="bold">
              {displaydata.length>0? displaydata[0][field[0]]>value?suffix[0]:suffix[1]:suffix[1] }
            </SoftTypography>
          )}
          
        </SoftTypography>
        <p></p>
        <SoftTypography variant="h8" fontWeight="light" textTransform="capitalize">
          {title}
        </SoftTypography>
        {description && (
          <SoftTypography
            variant="button"
            fontWeight="light"
            opacity={0.8}
            textTransform="capitalize"
          >
            {description}
          </SoftTypography>
        )}
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of DefaultCounterCard
InfoCounterCard.defaultProps = {
  color: "info",
  prefix: "",
  suffix: "",
  description: "",
};

// Typechecking props for the BlogCard
InfoCounterCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
   
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  prefix: PropTypes.array,
  suffix: PropTypes.string,
  iot_host:PropTypes.string.isRequired,
  iot_id:PropTypes.string.isRequired,
  field: PropTypes.array,
  value: PropTypes.number,
 
};

export default InfoCounterCard;
