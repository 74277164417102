import axios from 'axios';
import PropTypes from "prop-types";
import {useState ,useEffect,useCallback} from 'react';
 
import Card from "@mui/material/Card";
 
import config from '../../../../config.json';
 
 
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";


function CountCard({ color, title, description,iot_host,devicetype,count_field , count_device}) {
 

  const [replydata, setReplyData] = useState([]);
  const [returnvalue, setReturnValue] = useState(0);
  const [totalcount, totalCount] = useState(count_device.length);
  const [poststring, setPostString] = useState({
    "gateway": 
    {
               "host": iot_host,
               "type": devicetype,
               "iot_id":count_device
    },

    "field":
    {
        "col":count_field
    },
    
     

});

const headers = {
  'Content-Type': 'text/plain',
  'Access-Control-Allow-Credentials':'ture',
  'Access-Control-Allow-Origin':'*'

}

const [state, setState] = useState("state");

const loadDataOnlyOnce = useCallback(() => {
        console.log(`I need ${state}!!`);
      }, [state]);

const postData = async () =>{
       
    
        await axios.post(config.mqtt_count, poststring,{headers: headers})
        .then((response) => {
            
            try
            {
              
              //console.log(response.data)
              setReturnValue(response.data.value)
              
            }
            catch (error) {console.log(error)}
        })
        .catch((error) => console.log(error));
        
        
        
       
      
         
      }
      useEffect(() => {
        console.log("STart Counter")
        postData ();
       
      
      }, [])

  /*console.log(count_field );*/
  /*console.log(count_device.length);*/
  return (
    <Card>
      <SoftBox p={3} textAlign="center" lineHeight={1.25}>
        <SoftTypography variant="h1" color={color} fontWeight="bold" textGradient>
          {returnvalue && (
            <SoftTypography component="span" variant="h1" fontWeight="bold">
              {returnvalue} 
            </SoftTypography>
          )}

          / 
          
          {totalcount && (
            <SoftTypography component="span" variant="h1" fontWeight="bold">
               {totalcount}
            </SoftTypography>
          )}

        </SoftTypography>
        <SoftTypography variant="h6" fontWeight="bold" textTransform="capitalize">
          {title}
        </SoftTypography>
        {description && (
          <SoftTypography
            variant="button"
            fontWeight="regular"
            opacity={0.8}
            textTransform="capitalize"
          >
            {description}
          </SoftTypography>
        )}
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of DefaultCounterCard
CountCard.defaultProps = {
  color: "info",
  prefix: "",
  suffix: "",
  description: "",
};

// Typechecking props for the BlogCard
CountCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
   
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  devicetype:PropTypes.string,
  iot_host:PropTypes.string.isRequired,
  count_field: PropTypes.string,
  count_device: PropTypes.array,
  refresh :PropTypes.number,

};

export default CountCard;
