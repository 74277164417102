import React from 'react';
import { AiOutlineCalendar, AiOutlineShoppingCart, AiOutlineAreaChart, AiOutlineBarChart, AiOutlineStock } from 'react-icons/ai';
import { FiShoppingBag, FiEdit, FiPieChart  } from 'react-icons/fi';
import { BsKanban, BsBarChart  } from 'react-icons/bs';
import { BiColorFill } from 'react-icons/bi';
import { IoMdContacts } from 'react-icons/io';
import { RiContactsLine, RiStockLine } from 'react-icons/ri';
 
import { GiLouvrePyramid } from 'react-icons/gi';
 

export const itemlinks = [
    {
      title: 'Dashboard',
      links: [
 
        {
            name: 'JEC',
            icon: <IoMdContacts />,
          },
      ],
    },
  
    {
      title: 'Management',
      links: [
        {
          name: 'Device',
          icon: <AiOutlineShoppingCart />,
        },
        {
          name: 'Login',
          icon: <IoMdContacts />,
        },
        {
          name: 'User',
          icon: <RiContactsLine />,
        },
      ],
    },
 
   
  ];